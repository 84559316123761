import React from "react";

import {
  Box,
  Grid,
  Card,
  CardContent,
  Typography,
  styled,
  Button,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { landingBack, storeicon } from "./assets";
import { configJSON } from "./LandingPageController";
const Carousel = require("react-material-ui-carousel").default

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 992,
      lg: 1280,
      xl: 1920,
    },
  },
});
interface StoreCardItem {
  numberTerminals: number;
  numberCardDevices: number;
  cardTransactionsMTD: string;
  cashDepositorMTD: string;
  loanStatus: string;
  outStandingLoanAmount: number;
  referenceNumber: string;
  PosStatus: string;
  walletBalance: number;
  overallCommissions: number;
  buxBalance: number;
  bonusBalance: number;
}

const BackBox = styled(Box)({
  maxWidth: "1173px",
  width: "100%",
marginBottom:'20px',
  padding:'0 20px',
  "& .carouselWrapper2":{
    overflow:'visible',
    "&>div":{
      overflow:'hidden',
      "&:nth-child(2)":{
        right:'-20px',
        "@media(max-width: 992px)":{
          display:'none'
        }
      },
      "&:nth-child(3)":{
        left:'-20px',
        "@media(max-width: 992px)":{
          display:'none'
        }
      }
    }
  },
  "@media(max-width: 600px)": {
    "& .carouselWrapper":{
    height:'100%',
    overflow:'visible',
    "&>div":{
      overflow:'hidden',
      "&:first-child":{
        height:'100% !important',
        "& img":{
          height:'100%'
        }
      },}},
    width: "100%",
  },
});
const RightBoxLanding = styled(Box)({
  "@media(min-width: 992px)": {
    marginLeft: 150,
  },
});

const NavBtn2 = styled('div')({
  color:'#C12030',
  backgroundColor:"#fff",
  height:'40px',
  width:'40px',
  justifyContent:'center',
  display:'flex',
  fontSize:'20px',
  fontWeight:400,
  alignItems:'center',
  borderRadius:'50%',
  left:'20px',
  fontFamily:'Poppins',
})
// Customizable Area End

import LandingPageController, { Props } from "./LandingPageController";
import OperatorSideBar from "../../../components/src/OperatorSideBar.web";
import CommonHeaderOperator from "../../../components/src/CommonHeaderOperator.web";

export default class OperatorLandingPage extends LandingPageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderCardGrid = () => {
    return  configJSON.storeCardData.filter((_:StoreCardItem,index:number)=>index===0).map((value: StoreCardItem, index: number) =>(
      <Grid
        item
        xs={12}
        md={5}
        lg={4}
        data-test-id={`storeCardTestID${index}`}
        key={index}
        style={{padding:0}}
      >
        <Box
          style={webStyle.cardStyle}
        >
          <CardContent style={webStyle.cardContent}>
            <Box sx={{ marginBottom: 2 }}>
              <img src={storeicon} alt="Store" style={webStyle.image} />
            </Box>
            <Typography style={webStyle.topHeader2}>
              Khandal saree store, 403
            </Typography>
            <Box style={webStyle.cardTextss}>
              <Typography style={webStyle.refrences}>
                Reference Number:
              </Typography>
              <Typography style={webStyle.referenceThrees}>
                {value.referenceNumber}
              </Typography>
            </Box>
            <Box style={webStyle.cardTextss}>
              <Typography style={webStyle.refrences}>
                POS License Status:
              </Typography>
              <Typography
                style={{
                  ...webStyle.activeText,
                  color: value.PosStatus === "Active" ? "#059669" : "#C12030",
                }}
              >
                {value.PosStatus}
              </Typography>
            </Box>
            <Box style={webStyle.cardTextss}>
              <Typography style={webStyle.refrences}>
                Wallet Balance:
              </Typography>
              <Typography style={webStyle.refrences}>
                {value.walletBalance}
              </Typography>
            </Box>
            <Box style={webStyle.cardTextss}>
              <Typography style={webStyle.refrences}>
                Overall Commissions:
              </Typography>
              <Typography style={webStyle.refrences}>
                {value.overallCommissions}
              </Typography>
            </Box>
            <Box style={webStyle.cardTextss}>
              <Typography style={webStyle.refrences}>
                Bux Account Balance:
              </Typography>
              <Typography style={webStyle.refrences}>
                {value.buxBalance}
              </Typography>
            </Box>
            <Box style={webStyle.cardTextss}>
              <Typography style={webStyle.refrences}>
                Bonus Account Balance:
              </Typography>
              <Typography style={webStyle.refrences}>
                {value.bonusBalance}
              </Typography>
            </Box>
            <Box style={webStyle.cardTextss}>
              <Typography style={webStyle.refrences}>
                No. Terminals:
              </Typography>
              <Typography style={webStyle.refrences}>
                {value.numberTerminals}
              </Typography>
            </Box>
            <Box style={webStyle.cardTextss}>
              <Typography style={webStyle.refrences}>
                No. Card Devices:
              </Typography>
              <Typography style={webStyle.refrences}>
                {value.numberCardDevices}
              </Typography>
            </Box>
            <Box style={webStyle.cardTextss}>
              <Typography style={webStyle.refrences}>
                Card Transactions MTD:
              </Typography>
              <Typography style={webStyle.refrences}>
                {value.cardTransactionsMTD}
              </Typography>
            </Box>
            <Box style={webStyle.cardTextss}>
              <Typography style={webStyle.refrences}>
                Cash Depositor MTD:
              </Typography>
              <Typography style={webStyle.refrences}>
                {value.cashDepositorMTD}
              </Typography>
            </Box>
            <Box style={webStyle.cardTextss}>
              <Typography style={webStyle.refrences}>Loan Status:</Typography>
              <Typography
                style={{
                  ...webStyle.activeText2,
                  color: this.handleLoanStatusColor(value.loanStatus),
                }}
              >
                {value.loanStatus}
              </Typography>
            </Box>
            <Box style={webStyle.cardTextss}>
              <Typography style={webStyle.refrences}>
                Outstanding Loan Amount:
              </Typography>
              <Typography style={webStyle.refrences}>
                {value.outStandingLoanAmount}
              </Typography>
            </Box>
          </CardContent>
        </Box>
      </Grid>
    ));
  };

  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
      <Box style={{ display: "flex",boxSizing:'border-box' } as React.CSSProperties}>
        <OperatorSideBar />
        <RightBoxLanding style={webStyle.secondContainer}>
          <Box style={{ width: "100%" } as React.CSSProperties}>
            <CommonHeaderOperator anchor={"right"} style={{ width: "100%" }} />
          </Box>
          <BackBox>
              <Carousel 
              NextIcon={<NavBtn2 >{'>'}</NavBtn2>} 
              className="carouselWrapper2" 
              navButtonsWrapperProps={{   
                style: {
                  top: '50%',
                  height: '40px',
                  borderRadius: '50%',
                  width: '40px',
                  transform: 'translate(0, -50%)'
                }
              }}
              indicators={false} 
              PrevIcon={<NavBtn2>{"<"}</NavBtn2>}
              swipe={true} 
              navButtonsAlwaysVisible={true} 
              fullHeightHover={false}    
              duration={1000}
              animation="slide" 
              navButtonsProps={{          
                  style: {
                    borderRadius: '50%',
                    backgroundColor: '#fff',
                    height: '40px',
                    width: '40px',
                    
                    margin: 0,
                  }
                }}
               
              >

                <img
                  src={landingBack}
                  style={{
                    ...webStyle.image1,
                  }}
                />
                <img
                  src={landingBack}
                  style={{
                    ...webStyle.image1,
                  }}
                />
                <img
                  src={landingBack}
                  style={{
                    ...webStyle.image1,
                  }}
                />
              </Carousel>
          </BackBox>
          <Grid
            container
            style={{
              borderRadius: 12,
              width: "100%",
              maxWidth: "1173px",
              background: "transparent",
            }}
          >
            <Grid container spacing={2} style={webStyle.mainContainerStyle}  >
              {this.renderCardGrid()}
            </Grid>
            {/* <Button
             data-test-id="seeMoreButtonTestID"
             style={webStyle.seeMoreButtonStyle}>
              {configJSON.seeMoreButtonText}
            </Button> */}
          </Grid>
        </RightBoxLanding>
      </Box>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  cardStyle: {
    maxWidth: '345px',
    // flex: '0 1 25%',
    backgroundColor:'#fff',
    boxShadow: "0px 0px 5px lightgray",
    cursor: "pointer",
    padding:'30px',
    borderRadius:'12px',
    margin: ' 0 auto'
  } as React.CSSProperties,

  image1: {
    width: "100%",
  } as React.CSSProperties,
  secondContainer: {
    display: "flex",
    flexGrow: 1,
    alignItems: "center",
    flexDirection: "column",
    backgroundColor: "#E2E8F0",
  } as React.CSSProperties,

  cardTextss: {
    display: "flex",
    gap: "8px",
    justifyContent: "space-between",
    flexDirection: "row",
  } as React.CSSProperties,
  topHeader2: {
    fontWeight: 500,
    fontFamily: "Poppins",
    fontsize:'18px'
  },
  refrences: {
    fontWeight: 400,
    fontSize: "16px",
    fontFamily: "Poppins",
  } as React.CSSProperties,
  image: {
    width: "100%",
    height: "248px",
    borderRadius: "8px",
  },
  referenceThrees: {
    fontFamily: "Poppins",
    color: "#C12030",
    fontSize: "16px",
    fontWeight: 400,
  } as React.CSSProperties,
  activeText: {
    fontSize: "16px",
    fontFamily: "Poppins",
    fontWeight: 400,
    color: "#059669",
  },
  activeText2: {
    textAlign:'right',
    fontSize: "16px",
    fontFamily: "Poppins",
    fontWeight: 400,
    color: "#059669",
  }as React.CSSProperties,
  mainContainerStyle: {
    // display: "flex",
    width:'100%',
    // flexDirection: "row",
    // flexWrap: "wrap",
    // justifyContent: "flex-start",
    backgroundColor: "transparent",
    border: "10px",
    alignItems: "center",
    marginTop: 20,
    // gap: 30,
    margin: "auto",
    padding: '20px 0',
  } as React.CSSProperties,
  seeMoreButtonStyle: {
    fontFamily: "Poppins",
    backgroundColor: "#C12030",
    margin: "32px auto 30px",
    textTransform: "capitalize",
    fontWeight: 400,
    width: "228px",
    height: "50px",
    color: "#fff",
    fonstSize: "16px",
  } as React.CSSProperties,
  cardContent:{
    padding:'10px',
    boxShadow: "0px 0px 5px lightgray",

  }
};
// Customizable Area End
