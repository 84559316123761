import React from "react";

import {
    Box,
    Button,
    Typography,
    MenuItem,
    Select,
    FormControl,
    Grid,
    // Customizable Area Start
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import SideNavbar from "../../../components/src/SideBar.web";
import CommonDrawer from '../../../components/src/CommonSidebar.web';
import { Toaster } from "react-hot-toast"


const theme = createTheme({
    palette: {
        primary: {
            main: "#fff",
            contrastText: "#fff",
        },
    },
    typography: {
        h6: {
            fontWeight: 500,
        },
        subtitle1: {
            margin: "20px 0px",
        },
    },
});
import { arrow_back, downArrow, image_expand, leftArrow } from "./assets";
import { getStorageData } from "../../../framework/src/Utilities";


const RightBoxLanding2 = styled(Box)({
    '@media(min-width: 992px)': {
        marginLeft: 150
    }
})

const CustomTypography = styled(Typography)({
    paddingLeft: '54px',
    '@media(max-width: 600px)': {
        paddingLeft: '20px'
    }
})
const CustomBox = styled(Box)({
    backgroundColor: '#ffffff',
    maxWidth: "690px",
    width: '100%',
    height: "676px",
    paddingTop: "30px",
    borderRadius: '12px',
    marginTop: '25px',
    marginLeft: '54px',
    '@media(max-width: 600px)': {
        width: '90%',
        margin: '20px auto'
    }
})
const CustomButton = styled(Button)({
    backgroundColor: "#C12030",
    fontSize: "16px",
    marginTop: "30px",
    color: "#fff",
    maxWidth: '600px',
    width: "100%",
    height: "50px",
    borderRadius: "8px",
    textTransform: "capitalize",
    fontFamily: 'Poppins',
    fontWeight: 400,
    '@media(max-width: 600px)': {
        marginTop: "10px",
    },
    '&:hover': {
        backgroundColor: "#C12030",
    }
});

const StoreBox = styled(Box)({
    maxWidth: "600px",
    width: '100%',
    height: "200px",
    borderRadius: '12px',
    marginTop: '40px',
    paddingLeft: '15px',
    paddingRight: '15px',
    marginLeft: '30px',
    marginRight: '30px',
    paddingTop: '15px',
    boxShadow: '5px 5px 7px 6px rgba(0, 0, 0, 0.1)',
    '@media(max-width: 600px)': {
        width: '80%',
        margin: '20px auto'
    }
})
const WalletBox = styled(Box)({
    maxWidth: "600px",
    width: '100%',
    height: "270px",
    borderRadius: '12px',
    marginTop: '25px',
    paddingLeft: '15px',
    paddingRight: '15px',
    marginLeft: '30px',
    marginRight: '30px',
    paddingTop: '15px',
    boxShadow: '5px 5px 7px 6px rgba(0, 0, 0, 0.1)',
    '@media(max-width: 600px)': {
        width: '80%',
        margin: '20px auto'
    }
})
const WalletTop = styled(Box)({
    '@media(max-width: 992px)': {
      marginTop: "0 !important"
    }
  })
  
  const WalletText = styled(Typography)({
    '@media(max-width: 900px)': {
      fontSize: "16px !important"
    }
  })
// Customizable Area End

import Cfwallet53Controller, {
    Props,
    configJSON,
} from "./Cfwallet53Controller";

export default class Commision extends Cfwallet53Controller {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    componentDidMount = async () => {
        let showSuccess = await getStorageData('showSuccess')
        if (showSuccess != null) {
            this.handleSuccess()
        }

    }
    // Customizable Area End

    render() {
        const { selected, selectStore, storeError } = this.state;
        return (
            // Customizable Area Start
            <Box>
                <SideNavbar />
                <RightBoxLanding2 style={webStyle.secondContainer}>
                <Box style={webStyle.HeadersMain}>
            <WalletTop style={{ paddingLeft: "40px" }}>
              <WalletText style={{ fontSize: "24px", fontFamily: 'Inter', fontWeight: 700 }}>Commission</WalletText>
            </WalletTop>
            <Box>
              <CommonDrawer anchor={"right"} style={{ width: '100%' }} />
            </Box>
          </Box>
                    <CustomBox style={webStyle.backContainer}>
                        <Box style={{ display: 'flex', marginLeft: 40 }} 
                            data-test-id={"back-arrow"}>
                            <img src={leftArrow} style={{ width: '24px', height: '24px', cursor: 'pointer', }} onClick={() => this.handleBackToWalletTransact()} data-test-id={'handleBackToWalletTransactId'}/>
                            <Typography style={webStyle.backIcon} onClick={() => this.handleBackToWalletTransact()} data-test-id={'handleBackToWalletTransactTextId'}>Back</Typography>
                        </Box>
                        <StoreBox style={webStyle.storeContainer}>
                            <label style={{ ...webStyle.storeLable, color: this.state.storeError ? 'red' : 'inherit' }}>
                                Select Store
                            </label>
                            <FormControl variant="outlined" required style={webStyle.formStyle}>
                                <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    data-test-id={'selectStoreId'}
                                    value={this.state.selectStore}
                                    error={this.state.storeError}
                                    onChange={this.handleChange}
                                    MenuProps={{
                                        anchorOrigin: {
                                            vertical: 'bottom',
                                            horizontal: 'left',
                                        },
                                        transformOrigin: {
                                            vertical: 'top',
                                            horizontal: 'left',
                                        },
                                        getContentAnchorEl: null,
                                    }}
                                    style={{ width: '100%', height: '50px', borderRadius: "8px", fontFamily: 'Poppins', }}
                                >
                                    <MenuItem disabled={true} value={1} style={webStyle.selectStoreStyle}>Select the store</MenuItem>
                                    <MenuItem value={'Kahndal Saree Store'} style={webStyle.selectStoreStyle}>Kahndal Saree Store</MenuItem>
                                    <MenuItem value={'Pretty Saree Store'} style={webStyle.selectStoreStyle}>Pretty Saree Store</MenuItem>
                                    <MenuItem value={'Linen Saree Shop'} style={webStyle.selectStoreStyle}>Linen Saree Shop</MenuItem>
                                </Select>
                            </FormControl>
                            <Typography style={webStyle.storeDetailsText}>Your Commission</Typography>
                            <Typography style={webStyle.storeDetailsText2}>R {this.state.amount ? this.state.amount : 0}</Typography>
                        </StoreBox>
                        <WalletBox style={webStyle.walletConatiner}>
                            <Box style={webStyle.boxStyle}>
                                <Typography style={webStyle.transferText}>Transfer to</Typography>
                                <Typography style={webStyle.transferText2}>Wallet</Typography>
                                <img src={downArrow} style={{ width: '24px', height: '24px', cursor: 'pointer', display: 'flex', alignSelf: 'center', marginLeft: '16px' }} />
                            </Box>
                            <Box style={webStyle.textContainer}>
                                <Box style={webStyle.textBox}>
                                    <Typography style={webStyle.smalltext as React.CSSProperties}>R</Typography>
                                    <input
                                        className="no-outline"
                                        type="text"
                                        name="username"
                                        placeholder="Enter amount"
                                        value={this.state.amount}
                                        data-test-id = 'USERNAME'
                                        onChange={this.handleSetEndAmount}
                                        style={webStyle.inputStyle} />
                                </Box>
                            </Box>
                            <Box style={webStyle.amountBox}>
                <Button style={webStyle.amountBoxStyle} data-test-id="btnAddAmountFirst" onClick={() => this.onClickHandler(300)}>
                  <Typography style={webStyle.amountText}>+R300</Typography>
                </Button>
                <Button style={webStyle.amountBoxStyle} data-test-id="btnAddAmountSecond" onClick={() => this.onClickHandler(500)}>
                  <Typography style={webStyle.amountText}>+R500</Typography>
                </Button>
                <Button style={webStyle.amountBoxStyle} data-test-id="btnAddAmountThird" onClick={() => this.onClickHandler(1000)}>
                  <Typography style={webStyle.amountText}>+R1000</Typography>
                </Button>
              </Box>

                            <CustomButton  onClick={() => this.CommisionWallet()} data-test-id={'buttonPressAdd'}>
                                Proceed to Add
                            </CustomButton>


                        </WalletBox>

                    </CustomBox>

                    <Toaster position="top-center" toastOptions={{
                        success: {
                            style: {
                                padding: '16px',
                            },
                        },
                    }} />

                </RightBoxLanding2>
            </Box>

            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyle = {

    secondContainer:
        {
            backgroundColor: "#E2E8F0",
            height: '950px'
        } as React.CSSProperties,
    mainContainer: {
        display: "flex",
        flexDirection: "column", // This will stack the child elements vertically
        alignItems: "center",
        justifyContent: "center",
        maxWidth: "1173px",
        width: '100%',
        height: "851px",
        backgroundColor: '#fff'
    } as React.CSSProperties,
    toaster: {
        position: 'fixed',
        top: '50px', /* Default top position */
        left: '50%', /* Center horizontally */
        transform: 'translateX(-50%)', /* Center horizontally */
        backgroundColor: '#333',
        color: '#fff',
        padding: '10px 20px',
        borderRadius: '5px',
    },

    /* Media query for mobile devices */
    '@media (max-width: 600px)': {
        top: '20px',
        height: 'auto',
        padding: '100px 200px',
        backgroundColor: 'red'
    } as React.CSSProperties,
    backContainer: {
        // backgroundColor: '#ffffff',
        // maxWidth: "690px",
        // width: '100%',
        // height: "676px",
        // paddingTop: "30px",
        // borderRadius: '12px',
        // marginTop: '25px',
        // marginLeft: '54px'
    },
    backIcon: {
        fontFamily: 'Poppins',
        fontSize: '16px',
        fontWeight: 500,
        color: '#000',
        paddingLeft: 8,
        cursor: 'pointer'
    },
    storeContainer: {
        // maxWidth: "600px",
        // width: '100%',
        // height: "200px",
        // borderRadius: '12px',
        // marginTop: '40px',
        // paddingLeft: '15px',
        // paddingRight: '15px',
        // marginLeft: '30px',
        // marginRight: '30px',
        // paddingTop: '15px',
        // boxShadow: '5px 5px 7px 6px rgba(0, 0, 0, 0.1)',
    },
    storeLable: {
        fontFamily: "Poppins",
        fontSize: "16px",
        fontWeight: 400,
        color: "#273567",
    },
    formStyle: {
        border: `0.5px #273567`,
        borderRadius: "18px",
        width: '100%'
    },
    storeDetailsText: {
        color: '#0000000',
        fontFamily: 'Poppins',
        fontWeight: 600,
        fontSize: '24px',
        marginTop: '8px'
    },
    storeDetailsText2: {
        color: '#0000000',
        fontFamily: 'Poppins',
        fontWeight: 400,
        fontSize: '24px',
        marginTop: '8px'
    },
    walletConatiner: {
        // maxWidth: "600px",
        // width: '100%',
        // height: "270px",
        // borderRadius: '12px',
        // marginTop: '25px',
        // paddingLeft: '15px',
        // paddingRight: '15px',
        // marginLeft: '30px',
        // marginRight: '30px',
        // paddingTop: '15px',
        // boxShadow: '5px 5px 7px 6px rgba(0, 0, 0, 0.1)',

    },
    boxStyle: {
        display: 'flex',
        marginTop: '8px'
    },
    transferText: {
        color: '#000',
        fontFamily: 'Poppins',
        fontSize: '24px',
        fontWeight: 500
    },
    transferText2: {
        color: '#C12030',
        fontFamily: 'Poppins',
        fontSize: '24px',
        fontWeight: 500,
        marginLeft: '5px'
    },
    textContainer: {
        display: 'flex',
        borderRadius: '12px',
        justifyContent: 'space-between',
        border: '0.5px solid #273567',
        alignItems: 'center',
        width: '96%',
        paddingLeft: '10px',
        paddingRight: '10px',
        height: '50px',
        marginTop: 22
    },
    textBox: {
        display: 'flex'
    },
    smalltext: {
        alignSelf: 'center',
        fontWeight: '500',
        marginRight: '1%',
        fontFamily: 'Poppins',
        fontSize: '14px',
        color: '#C12030'
    },
    inputStyle: {
        color: "#000000",
        fontFamily: 'Poppins',
        border: 'none',
        borderColor: 'white',
        fontWeight: 500,
        fontSize: '14px',
        alignSelf: 'center',
        outline: 'none',
        width: '70%',

    },
    amountBox: {
        display: 'flex',
        marginTop: '20px',
        justifyContent: 'flex-end'
    },
    amountBoxStyle: {
        height: '32px',
        maxWidth: '73px',
        width: '100%',
        borderRadius: '12px',
        borderWidth: '5px',
        border: '1px solid #273567',
        marginRight: '25px'
    },
    amountText: {
        color: '#0000000',
        fontFamily: 'Poppins',
        fontWeight: 400,
        fontSize: '14px',
        textAlign: 'center'
    } as React.CSSProperties,

    selectStoreStyle: {
        fontFamily: 'Poppins',
        fontWeight: 500,
        fontSize: '14px',
        textAlign: 'center'
    } as React.CSSProperties,
    HeadersMain: { width: '100%', backgroundColor: "rgb(226, 232, 240)", display: "flex", alignItems: "center", justifyContent: "space-between" } as React.CSSProperties,

};
// Customizable Area End
