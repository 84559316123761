import React from "react";
import { Box, Button, Card, CardContent, FormControl, MenuItem, Select, Typography, styled } from "@material-ui/core";
import Cfwallet53Controller, { Props } from "./Cfwallet53Controller";
import CommonDrawer from '../../../components/src/CommonSidebar.web';
import SideNavbar from "../../../components/src/SideBar.web";
import { BackIcn } from './assets';
import { Toaster } from "react-hot-toast"

const LayoutMain = styled(Box)({
    '@media(min-width: 992px)': {
        marginLeft: 150
    }
})

const BillsText = styled(Box)({
    '@media(max-width: 992px)': {
        marginTop: "0 !important"
    }
})

const PayText = styled(Typography)({
    '@media(max-width: 900px)': {
        fontSize: "16px !important"
    }
})

const SelectText = styled(Typography)({
    '@media(max-width: 900px)': {
        fontSize: "14px !important"
    },
    '@media(max-width: 400px)': {
        fontSize: "12px !important"
    }
})

export default class PayBillToStore extends Cfwallet53Controller {
    constructor(props: Props) {
        super(props);
    }

    render() {
        return (
            <Box style={{ display: "flex" }}>
                <SideNavbar />
                <LayoutMain style={{ width: "100%", backgroundColor: "#F6F7FA" }}>
                    <Box style={webStyle.HeadersMain}>
                        {/* { Pay bill font change} */}
                        <BillsText style={{ marginTop: "20px", paddingLeft: "40px" }}>
                            <PayText style={{ fontSize: "24px", fontFamily: 'Inter', fontWeight: 700 }}>Pay Bills</PayText>
                        </BillsText>
                        <Box>
                            <CommonDrawer anchor={"right"} style={{ width: '100%' }} />
                        </Box>
                    </Box>

                    <Box style={{ padding: "40px", backgroundColor: "rgb(226, 232, 240)" }}>
                        <Card style={{ maxWidth: "690px", padding: "30px",borderRadius:"12px" }}>

                            <CardContent style={{ maxWidth: "100%", display: "flex", gap: 32, flexDirection: "column" }}>
                                <Box style={{ cursor: "pointer", marginBottom: "30px", maxWidth: "80px" }} data-test-id = "backArrowtransact"  onClick={this.handleNavtoWallet}>
                                    <Typography style={{ fontSize: "16px", display: "flex", alignItems: "center", fontFamily: 'Poppins', fontWeight: 500, }}>
                                        <img src={BackIcn} alt="back-icn" />
                                        Back
                                    </Typography>
                                </Box>
                                <Card style={{ width: "100%", maxWidth: "630px", boxShadow: "0px 1px 4px 1px rgba(0,0,0,0.1)" }}>

                                    <CardContent style={{ padding: "15px", maxWidth: "100%" }}>


                                        <Box style={{ margin: "22px 0" }}>
                                            <SelectText style={{
                                                fontFamily: "Poppins",
                                                fontSize: "16px",
                                                fontWeight: 400,
                                                letterSpacing: "0em",
                                                textAlign: "left",
                                                color: "#273567",
                                                marginBottom: '2px', // Adjust margin-bottom for reduced space
                                                display: 'block',  // Ensure the label is displayed as a block element
                                            }}
                                            >
                                                Select store
                                            </SelectText>
                                            <FormControl variant="outlined" required style={{
                                                border: `0.5px #273567`, borderRadius: "18px", width: '100%'
                                            }}>
                                                <Select
                                                    labelId="demo-simple-select-outlined-label"
                                                    id="demo-simple-select-outlined"
                                                    data-test-id={'selectStoreId'}
                                                    onChange={this.handleChangeStore}

                                                    MenuProps={{
                                                        anchorOrigin: {
                                                            vertical: 'bottom',
                                                            horizontal: 'left',
                                                        },
                                                        transformOrigin: {
                                                            vertical: 'top',
                                                            horizontal: 'left',
                                                        },
                                                        getContentAnchorEl: null,
                                                    }}
                                                    style={{
                                                        width: '100%', height: '50px', fontFamily: "Poppins", fontSize: "14px", color: "#273567",
                                                        fontWeight: 400, borderRadius: '8px',
                                                    }}
                                                >
                                                    <MenuItem value={"KahndalSareeStore"} style={{ fontFamily: "Poppins", fontWeight: 500, fontSize: "14px" }}>Kahndal Saree Store</MenuItem>
                                                    <MenuItem value={'KahndalSareeStore1'} style={{ fontFamily: "Poppins", fontWeight: 500, fontSize: "14px" }}>Kahndal Saree Store</MenuItem>
                                                    <MenuItem value={'KahndalSareeStore2'} style={{ fontFamily: "Poppins", fontWeight: 500, fontSize: "14px" }}>Kahndal Saree Store</MenuItem>
                                                    <MenuItem value={'ThiKahndalSareeStorerty3'} style={{ fontFamily: "Poppins", fontWeight: 500, fontSize: "14px" }}>Kahndal Saree Store</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Box>
                                        <Box style={{ margin: "22px 0" }}>
                                            <SelectText style={{
                                                fontFamily: "Poppins",
                                                fontSize: "16px",
                                                fontWeight: 400,
                                                letterSpacing: "0em",
                                                textAlign: "left",
                                                color: "#273567",
                                                marginBottom: '2px', // Adjust margin-bottom for reduced space
                                                display: 'block',  // Ensure the SelectText is displayed as a block element
                                            }}>
                                                Select Supplier
                                            </SelectText>
                                            <FormControl variant="outlined" required style={{
                                                border: `0.5px #273567`, borderRadius: "18px", width: '100%'
                                            }}>
                                                <Select
                                                    labelId="demo-simple-select-outlined-label"
                                                    id="demo-simple-select-outlined"
                                                    data-test-id={'selectStoreId'}
                                                    onChange={this.handleChangeSupplier}
                                                    MenuProps={{
                                                        anchorOrigin: {
                                                            vertical: 'bottom',
                                                            horizontal: 'left',
                                                        },
                                                        transformOrigin: {
                                                            vertical: 'top',
                                                            horizontal: 'left',
                                                        },
                                                        getContentAnchorEl: null,
                                                    }}
                                                    style={{
                                                        width: '100%', height: '50px', fontFamily: "Poppins", fontSize: "14px", color: "#273567",
                                                        fontWeight: 400, borderRadius: '8px',
                                                    }}
                                                >
                                                    <MenuItem value={"Anshu"} style={{ fontFamily: "Poppins", fontWeight: 500, fontSize: "14px" }}>Anshu</MenuItem>
                                                    <MenuItem value={'Krish'} style={{ fontFamily: "Poppins", fontWeight: 500, fontSize: "14px" }}>Krish</MenuItem>
                                                    <MenuItem value={'TweBishtnty'} style={{ fontFamily: "Poppins", fontWeight: 500, fontSize: "14px" }}>Bisht</MenuItem>
                                                    <MenuItem value={'Samay'} style={{ fontFamily: "Poppins", fontWeight: 500, fontSize: "14px" }}>Samay</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Box>
                                        <Box style={{ paddingBottom: "10px" }}>
                                            <Box style={webStyle.fieldsMain as React.CSSProperties}>
                                                <SelectText style={webStyle.labelStyle as React.CSSProperties}>Invoice/ Reference Number</SelectText>
                                                <input
                                                    name="InvoiceNumber"
                                                    type="text"
                                                    data-test-id = 'invoicechange'
                                                    style={{

                                                        border: this.state.invoiceError === true ? "1px solid #FF0404" : "1px solid rgba(39, 53, 103, 0.5)",
                                                        ...webStyle.invoiceInput
                                                    }}
                                                    onChange={(e) => this.handleInvoice(e)}
                                                />
                                            </Box>
                                            {this.state.invoiceError === true ?
                                                <p style={{ marginBottom: "0", paddingBottom: "0", paddingTop: "10px", color: "#FF0404", fontFamily: "Poppins", fontWeight: 400 }}>Enter your correct Invoice/ Reference Number </p> : ""}
                                        </Box>
                                    </CardContent>
                                </Card>
                                <Button style={webStyle.operatorSubmit as React.CSSProperties} data-test-id="billtostore" onClick={this.BillToStore} >
                                    Next
                                </Button>
                            </CardContent>
                        </Card>
                    </Box>
                    <Toaster position="top-center" />
                </LayoutMain>
            </Box>
        );
    }
}
const webStyle = {
    HeadersMain: { width: '100%', backgroundColor: "rgb(226, 232, 240)", display: "flex", alignItems: "center", justifyContent: "space-between" } as React.CSSProperties,
    operatorSubmit: {
        backgroundColor: '#C12030',
        marginTop: '16px',
        fontSize: '16px',
        color: '#fff',
        height: '50px',
        borderRadius: '8px',
        textTransform: 'capitalize',
        width: "100%",
        maxWidth: "630px",
        fontFamily: 'Poppins',
        fontWeight: 500
    },
    fieldsMain: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "50px",
        margin: "20px 0",
    },
    labelStyle: {
        fontFamily: 'Poppins',
        color: "#273567",
        fontSize: "16px",
        lineHeight: " 24px",
        letterSpacing: "0em",
        fontWeight: "400",
    },
    invoiceInput: {
        color: '#273567',
        width: '100%',
        top: '26px',
        borderRadius: '8px',
        // border: '1px solid rgba(39, 53, 103, 0.5)',
        padding: "15px",
        fontFamily: 'Poppins',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 500,
        height: "50px",
    },

}