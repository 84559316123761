import React from "react";

import {
    Box,
    Typography,
    styled,
    Button,
    MenuItem,
    FormControl,
    Select
} from "@material-ui/core";
import UserProfileBasicController, {
    Props
} from "./UserProfileBasicController";
import { Toaster } from "react-hot-toast"
import SideNavbar from "../../../components/src/SideBar.web";
import CommonDrawer from '../../../components/src/CommonSidebar.web';
import Header from "../../../components/src/Header.web";
import { leftArrow } from "./assets"

const RightBoxLanding = styled(Box)({
    '@media(min-width: 992px)': {
        marginLeft: 150
    }
})

const StateSelect= styled(Select)({
  border:"1px solid #273567",
  '& .MuiOutlinedInput-notchedOutline': {
     border: 0 
  },
  "& .MuiSelect-select:focus":{
    backgroundColor:"transparent"
  },
  '&.Mui-focused': {
    border:"1.5px solid #273567",
    boxShadow:"0 0 0px 2px #3990e7"
  }
})

export default class AddOperatorSettings extends UserProfileBasicController {
    constructor(props: Props) {
        super(props);
    }
    componentDidMount = async () => {
        const operatorEmail = this.props.navigation.getParam('operatorEmail')
        this.handleGetOperatorSettingDetail(operatorEmail)
           }
    render() {
        const validateBorder =(name:string)=> this.state.requiredInput.includes(name) ? 'red' : '#273567'
        const renderPassportField = (name: any, label: any) => (
            <Box style={webStyle.operatorfieldsMain as React.CSSProperties}>
                <label style={webStyle.operatorlabelStyle as React.CSSProperties}>{label}</label>
                <input
                    type="text"
                    name={name}
                    maxLength={13}
                    style={{
                        MozAppearance: "textfield",
                        ...webStyle.operatorinputStyleAdd,
                        borderColor: validateBorder(name),
                    }}
                    onChange={this.handleInputChanges2}
                    value={this.state.passportId}
                />
                {this.state.requiredInput.includes(name) && <div style={{ color: 'red', marginTop: '5px', fontFamily: "poppins", fontSize: "12px", fontWeight: 400 }}>{`${label} is required`}</div>}
                {!!this.state.passportIdError && <div style={{ color: 'red', marginTop: '5px', fontFamily: "poppins", fontSize: "12px", fontWeight: 400 }}>{this.state.passportIdError}</div>}
            </Box>
        );
        const renderInputField = (name: any, label: any) => (
            <Box style={webStyle.operatorfieldsMain as React.CSSProperties}>
                <label style={webStyle.operatorlabelStyle as React.CSSProperties}>{label}</label>
                <input
                    type={name === "phoneNumberOp" ? "number" : "text"}
                    name={name}
                    maxLength={name==="nextOfKinOp"? 50:undefined}
                    style={{
                        MozAppearance: "textfield",
                        ...webStyle.operatorinputStyleAdd,
                        borderColor: validateBorder(name),
                    }}
                    onChange={this.handleInputChanges2}
                />
                {this.state.requiredInput.includes(name) && <div style={{ color: 'red', marginTop: '5px', fontFamily: "poppins", fontSize: "12px", fontWeight: 400 }}>{`${label} is required`}</div>}
            </Box>
        );
        const renderPhoneField = (name: any, label: any) => (
            <Box style={webStyle.operatorfieldsMain as React.CSSProperties}>
                <label style={webStyle.operatorlabelStyle as React.CSSProperties}>{label}</label>
                <input
                    type="text"
                    name={name}
                    style={{
                        MozAppearance: "textfield",
                        ...webStyle.operatorinputStyleAdd,
                        borderColor: validateBorder(name),
                    }}
                    onChange={this.handlePhoneNumberOpChange}
                    value={this.state.phoneNumberOp}
                />
                {!this.state.isPhoneNumberValid && <div style={{ color: 'red', marginTop: '5px', fontFamily: "poppins", fontSize: "12px", fontWeight: 400 }}>Invalid Phone Number</div>}
            </Box>
        );
        const renderEmailField = (name: any, label: any) => (
            <Box style={webStyle.operatorfieldsMain as React.CSSProperties}>
                <label style={webStyle.operatorlabelStyle as React.CSSProperties}>{label}</label>
                <input
                    type="text"
                    name={name}
                    style={{
                        MozAppearance: "textfield",
                        ...webStyle.operatorinputStyleAdd,
                        borderColor: validateBorder(name),
                    }}
                    onChange={this.handleEmailOpChange}
                    value={this.state.emailAddressOp}
                />
                {this.state.errorMessage && <div style={{ color: 'red', marginTop: '5px', fontFamily: "poppins", fontSize: "12px", fontWeight: 400 }}>{this.state.errorMessage}</div>}
            </Box>
        );
        return (
            // Customizable Area Start
            <Box style={webStyle.operatorSettingContainer as React.CSSProperties}>
                <SideNavbar />
                <RightBoxLanding style={webStyle.OperatorMain as React.CSSProperties}>
                    <Box style={webStyle.operatordrawerBox as React.CSSProperties}>
                        <CommonDrawer anchor={"right"} style={{ width: '100%' }} />
                    </Box>
                    <Header />
                    <Box style={{ padding: "20px 0 0 40px", display: 'flex', cursor: "pointer" }} data-test-id="backoperate" onClick={() => this.handlenavigateOperator()}>
                        <img src={leftArrow} style={{ width: '24px', height: '24px' }} />
                        <Typography style={{ fontFamily: 'Poppins', fontWeight: 500, color: '#000', fontSize: '16px'}}>Back</Typography>
                    </Box>
                    <Box style={webStyle.operatorSettingFeildsMian}>
                        <Box style={webStyle.operatorSettingTextMain}>
                            <Typography style={webStyle.operatorSettingText as React.CSSProperties}>
                                Operator Settings
                            </Typography>
                        </Box>

                        {renderInputField("nameOp", "Name & Surname",)}
                        {renderPassportField("passportId", "ID/Passport",)}
                        {renderInputField("citizenStatus", "Citizen Status",)}
                        {renderInputField("storeName", "Store Name",)}
                        {renderInputField("address", "Address",)}
                        {renderInputField("coordinates", "Coordinates",)}
                        {renderPhoneField("phoneNumberOp", "Phone Number",)}
                        {renderInputField("nextOfKinOp", "Next of Kin",)}
                        {renderEmailField("emailAddressOp", "Email Address",)}
                        {renderInputField("ethnicity", "Ethnicity",)}

                        <Box sx={webStyle.selectStoreBox} >
                            <label style={webStyle.labelStyle as React.CSSProperties}>
                                Gender
                            </label>
                            <FormControl variant="outlined" required style={webStyle.formControlStyle}>
                                {console.log(this.state.gender,"gendddd")
                                }
                                <StateSelect
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    name='gender'
                                    value={this.state.gender}
                                    onChange={this.handleInputGender}
                                    MenuProps={{
                                        anchorOrigin: {
                                            vertical: 'bottom',
                                            horizontal: 'left',
                                        },
                                        transformOrigin: {
                                            vertical: 'top',
                                            horizontal: 'left',
                                        },
                                        getContentAnchorEl: null,
                                    }}
                                    style={{
                                        ...webStyle.dropDownStyle,
                                        borderColor: this.state.requiredInput.includes('gender') ? 'red' : '#273567',
                                    }}
                                >

                                    <MenuItem value={"Male"} style={webStyle.selectStoreStyle}>
                                        Male
                                    </MenuItem>
                                    <MenuItem value={"Female"} style={webStyle.selectStoreStyle}>
                                        Female
                                    </MenuItem><MenuItem value={"others"} style={webStyle.selectStoreStyle}>
                                        Others
                                    </MenuItem>

                                </StateSelect>
                            </FormControl>
                        </Box>
                        {renderInputField("positions", "Position")}

                        <Button onClick={this.handleAddOperator} style={webStyle.operatorSubmit as React.CSSProperties}>
                            Submit
                        </Button>
                    </Box>
                    <Toaster position="top-center" />

                </RightBoxLanding>
            </Box>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyle = {

    operatorSettingContainer: {
        backgroundColor: "#E2E8F0"
    } as React.CSSProperties,

    operatorSettingFeildsMian: {
        maxWidth: "610px",
        backgroundColor: "#FFFFFF",
        borderRadius: "4px",
        padding: "20px",
        margin: "40px",
    } as React.CSSProperties,

    operatorSettingTextMain: {
        marginBottom: "40px"
    },

    operatorSettingText: {
        fontFamily: 'Poppins',
        fontWeight: '500',
        color: '#000000',
        lineHeight: '40px',
        fontSize: '20px'
    },

    // div styling
    div: {
        display: 'block'
    },

    operatorfieldsMain: {
        display: "flex",
        maxWidth: '100%',
        flexDirection: "column",
        width: "100%",
        margin: "20px 0",
    },

    settingTabMain: {
        alignItems: "center",
        display: "flex",
        gap: "12px",
        flexWrap: "wrap",
        padding: "20px 40px",

    },

    activesettingTab: {
        backgroundColor: "rgba(132, 143, 172, 0.1)",
        padding: "15px",
        borderRadius: "16px",
        maxWidth: "200px",
        width: "100%",
        textAlign: "center",
        cursor: "pointer"
    },

    activesettingTab2: {
        backgroundColor: "rgba(193, 32, 48, 0.03)",
        padding: "15px",
        borderRadius: "16px",
        maxWidth: "200px",
        textAlign: "center",
        width: "100%",
        cursor: "pointer",
        borderBottom: "2px solid #C12030"
    },

    settingTab: {
        backgroundColor: "rgba(132, 143, 172, 0.1)",
        padding: "15px",
        borderRadius: "16px",
        maxWidth: "200px",
        width: "100%",
        textAlign: "center",
        cursor: "pointer"
    },

    settingTab2: {
        backgroundColor: "rgba(132, 143, 172, 0.1)",
        borderRadius: "16px",
        padding: "15px",
        width: "100%",
        maxWidth: "200px",
        textAlign: "center",
        cursor: "pointer",
        borderBottom: "2px solid #848FAC"
    },


    operatordrawerBox: { width: '100%' },


    operatorSubmit: {
        backgroundColor: '#C12030',
        marginTop: '16px',
        fontSize: '16px',
        color: '#fff',
        height: '50px',
        borderRadius: '8px',
        textTransform: 'capitalize',
        width: "100%",
        fontFamily: 'Poppins',
    },


    OperatorMain:
    {
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'column',
        backgroundColor: "#E2E8F0",
    },

    greentickModal2: {
        position: 'relative',
        backgroundColor: '#fff',
        padding: '20px',
        borderRadius: '8px',
        margin: 'auto',
        marginTop: '100px',
        flexShrink: '0',
        flexDirection: 'column',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        boxShadow: '0px 4px 28px 0px rgba(131, 131, 131, 0.17)',
    },
    // label styling
    operatorlabelStyle: {
        fontFamily: 'Poppins',
        fontSize: "16px",
        color: "#273567",
        lineHeight: " 24px",
        letterSpacing: "0em",
        fontWeight: "400",
    },

    // input styling
    operatorinputStyleAdd: {
        color: '#273567',
        top: '26px',
        width: '100%',
        borderRadius: '8px',
        border: '1px solid',
        padding: "15px",
        height: '40px',
        fontFamily: 'Poppins',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 500,
    },

    // coordinate-box styling
    cordinateBox: {
        display: 'flex',
        position: 'relative',
        flexDirection: 'column',
        width: '100%'
    },

    captureIcon: {
        width: 'auto',
        right: '10',
        height: '30px',
        top: '30',
        position: 'absolute',
    },
    selectStoreBox: {
        display: 'flex',
        flexDirection: 'column',
        width: "100%"
    } as React.CSSProperties,
    formControlStyle: {
        borderRadius: "8px",
        background: "white"
    } as React.CSSProperties,
    dropDownStyle: {
        width: '100%',
        height: '40px',
        fontFamily: "Poppins",
        fontSize: "14px",
        color: "#273567",
        fontWeight: 500,
        borderRadius: '8px',
        background: "white"
    } as React.CSSProperties,
    selectStoreStyle: {
        fontFamily: 'Poppins',
        fontWeight: 500,
        fontSize: '14px',
        textAlign: 'center'
    } as React.CSSProperties,
    labelStyle: {
        fontFamily: 'Poppins',
        color: "#273567",
        fontSize: "16px",
        lineHeight: " 24px",
        letterSpacing: "0em",
        fontWeight: "400",
    },
}
// Customizable Area End